import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import {
  analytics,
  InternetStatus,
  Header,
  theme,
  AuthProvider,
} from "@project/shared";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useRouter } from "next/router";
import { Layout } from "antd";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../utils/globalStyles.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Head from "next/head";

const LayoutWrapper = styled.div<{ hideScroll: boolean }>`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${({ hideScroll }) => hideScroll && "hidden"};
  display: flex;
  background: ${theme.appBackground};
  justify-content: center;
  flex-direction: column;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout {
    background: ${theme.gray3};
    height: auto;
  }
  & .ant-layout-content {
    background: ${theme.gray3};
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 25vh;
  }
`;

const ContentWrapper = styled.div<{ hideScroll: boolean }>`
  height: 100%;
  flex: 1;
  display: ${({ hideScroll }) => hideScroll && "flex"};
  justify-content: ${({ hideScroll }) => hideScroll && "center"};
`;
export { Layout };

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `spomachi-frontend-admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}
const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const [collapse, setCollapse] = useState(false);
  const { t } = useTranslation();
  const { Content } = Layout;

  useEffect(() => {
    setCollapse(collapse);
  }, [collapse]);

  useEffect(() => {
    queryClient.prefetchQuery("user-info");
  }, [routers.pathname]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "SPOMACHI",
        });
      };

      routers.events.on("routeChangeComplete", logEvent);
      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <title>{process.env.NEXT_PUBLIC_PAGE_TITLE}</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <InternetStatus
        noNotification
        window={global.window}
        message={t("No Internet")}
        description={t(
          "Internet connection couldn't be established, please check your connection."
        )}
      />
      <AuthProvider>
        <LayoutWrapper hideScroll={routers.pathname === "/chat/[id]"}>
          <Layout>
            <Header />
            <Content>
              <ContentWrapper hideScroll={routers.pathname === "/chat/[id]"}>
                <Component {...pageProps} />
              </ContentWrapper>
            </Content>
          </Layout>
        </LayoutWrapper>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
